<template>
  <ion-page id="page-content">
    <ion-modal :is-open="showSort" :initialBreakpoint="$store.state.deviceHeight < 700 ? 0.45 : 0.4" @didDismiss="showSort = false" css-class="trans-sort-modal">
      <div class="modal-content trans-sort">
        <div class="title-box">
          <span class="close" @click="showSort = false"></span>
          <span class="font-18 main-color font-b">Select month</span>
          <span class="font-14 sub-color" @click="monthSelect">Apply</span>
        </div>
        <ul class="list-box">
          <li>
            <p class="font-16 label-color">This Month</p>
            <button class="check-btn" @click="monthPick = 0" :class="{ active: monthPick == 0 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past month</p>
            <button class="check-btn" @click="monthPick = 1" :class="{ active: monthPick == 1 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 2 Months</p>
            <button class="check-btn" @click="monthPick = 2" :class="{ active: monthPick == 2 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 3 Months</p>
            <button class="check-btn" @click="monthPick = 3" :class="{ active: monthPick == 3 }"></button>
          </li>
        </ul>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true" class="rewardHistory">
      <topbar :bigtitle="true" :class="{ 'letter-ios-05': $store.state.deviceType == 1 }" title="Rewards History" :showBack="true"></topbar>
      <div class="transaction-page margin-top-160">
        <div class="sort-box">
          <img style="margin-right: 10px" src="../assets/icon-calendar.png" />
          <span @click="showSort = true" class="font-16 sub-color font-m"> Select month </span>
        </div>
        <ul class="reward-list">
          <li v-for="(item, index) in rewardList" :key="index + 'list'" @click="goDetail(item)">
            <div class="img-box">
              <span class="label">{{ item.stampQty }} stamps<span v-if="item.cashQty"> + $</span></span>
              <img v-lazy="item.giftImage" :key="item.giftImage" />
            </div>
            <div class="detail">
              <p class="font-18 main-color font-m Medium">{{ item.giftName }}</p>
              <p class="font-16 gery-color font-m Medium">
                <span style="white-space: nowrap">Redemption date :</span> <br />
                {{ item.redeemTime ? formatDate(item.redeemTime.replaceAll("-", "/"), $store.state.form.merchantDetail.dateFormat) : item.redeemTime }}
              </p>
            </div>
            <span class="arrow-right"></span>
          </li>
        </ul>
        <ion-infinite-scroll @ionInfinite="loadData" threshold="100px" id="infinite-scroll" :disabled="isDisabled">
          <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data..."> </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonModal, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";

import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "rewardHistory",
  mixins: [config.globalMixin],
  components: {
    IonPage,
    IonContent,
    topbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonModal,
  },
  data() {
    return {
      showSort: false,
      rewardList: [],
      pageNum: 0,
      pageLimit: 20,
      isDisabled: false,
      startTime: this.$store.state.nowMonthStart,
      endTime: this.$store.state.nowMonthEnd,
      monthPick: 0,
      monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
    };
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        path: "/rewardRedeemedDetail",
        query: {
          cashQty: item.cashQty,
          giftImage: item.giftImage,
          giftName: item.giftName,
          redeemCode: item.redeemCode,
          redeemTime: item.redeemTime,
          stampQty: item.stampQty,
          giftDescr: item.giftDescr,
          redemption: this.formatDate(item.redeemTime.replaceAll("-", "/"), this.$store.state.form.merchantDetail.dateFormat),
        },
      });
    },
    monthSelect() {
      switch (this.monthPick) {
        case 0:
          this.startTime = this.$store.state.nowMonthStart;
          this.endTime = this.$store.state.nowMonthEnd;
          break;
        case 1:
          this.startTime = this.$store.state.lastMonthStart;
          this.endTime = this.$store.state.lastMonthEnd;
          break;
        case 2:
          this.startTime = this.$store.state.pastMonthStart;
          this.endTime = this.$store.state.pastMonthEnd;
          break;
        case 3:
          this.startTime = this.$store.state.lPastMonthStart;
          this.endTime = this.$store.state.lPastMonthEnd;
          break;
      }
      this.showSort = false;
      this.init();
    },
    async init(e) {
      const objdata = this.getAuthObj();
      objdata.pageNum = this.pageNum;
      objdata.pageLimit = this.pageLimit;
      objdata.apiUrl = this.apiConfig.listUserRedeemHistory;
      objdata.startTime = this.startTime;
      objdata.endTime = this.endTime;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (this.pageNum == 0) {
        this.rewardList = [];
      }
      data.data.result.forEach(item => {
        this.rewardList.push(item);
      });
      if (e) {
        e.target.complete();
      }
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled = true;
      }
    },
    loadData(e) {
      this.pageNum++;
      this.init(e);
    },
  },
  ionViewWillEnter() {
    this.rewardList = [];
    this.pageNum = 0;
    this.init();
    this.$store.state.exitApp = false;
    this.sendGAPageView("Rewards History");
  },
};
</script>
